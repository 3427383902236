import { SvgIcon, type SvgIconProps } from '@mui/material'

const InfoIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <path
        d="M7.99967 11.334C8.18856 11.334 8.3469 11.2701 8.47467 11.1423C8.60245 11.0145 8.66634 10.8562 8.66634 10.6673V8.00065C8.66634 7.81176 8.60245 7.65343 8.47467 7.52565C8.3469 7.39787 8.18856 7.33398 7.99967 7.33398C7.81079 7.33398 7.65245 7.39787 7.52467 7.52565C7.3969 7.65343 7.33301 7.81176 7.33301 8.00065V10.6673C7.33301 10.8562 7.3969 11.0145 7.52467 11.1423C7.65245 11.2701 7.81079 11.334 7.99967 11.334ZM7.99967 6.00065C8.18856 6.00065 8.3469 5.93676 8.47467 5.80898C8.60245 5.68121 8.66634 5.52287 8.66634 5.33398C8.66634 5.1451 8.60245 4.98676 8.47467 4.85898C8.3469 4.73121 8.18856 4.66732 7.99967 4.66732C7.81079 4.66732 7.65245 4.73121 7.52467 4.85898C7.3969 4.98676 7.33301 5.1451 7.33301 5.33398C7.33301 5.52287 7.3969 5.68121 7.52467 5.80898C7.65245 5.93676 7.81079 6.00065 7.99967 6.00065ZM7.99967 14.6673C7.07745 14.6673 6.21079 14.4923 5.39967 14.1423C4.58856 13.7923 3.88301 13.3173 3.28301 12.7173C2.68301 12.1173 2.20801 11.4118 1.85801 10.6007C1.50801 9.78954 1.33301 8.92287 1.33301 8.00065C1.33301 7.07843 1.50801 6.21176 1.85801 5.40065C2.20801 4.58954 2.68301 3.88398 3.28301 3.28398C3.88301 2.68398 4.58856 2.20898 5.39967 1.85898C6.21079 1.50898 7.07745 1.33398 7.99967 1.33398C8.9219 1.33398 9.78856 1.50898 10.5997 1.85898C11.4108 2.20898 12.1163 2.68398 12.7163 3.28398C13.3163 3.88398 13.7913 4.58954 14.1413 5.40065C14.4913 6.21176 14.6663 7.07843 14.6663 8.00065C14.6663 8.92287 14.4913 9.78954 14.1413 10.6007C13.7913 11.4118 13.3163 12.1173 12.7163 12.7173C12.1163 13.3173 11.4108 13.7923 10.5997 14.1423C9.78856 14.4923 8.9219 14.6673 7.99967 14.6673ZM7.99967 13.334C9.48856 13.334 10.7497 12.8173 11.783 11.784C12.8163 10.7507 13.333 9.48954 13.333 8.00065C13.333 6.51176 12.8163 5.25065 11.783 4.21732C10.7497 3.18398 9.48856 2.66732 7.99967 2.66732C6.51079 2.66732 5.24967 3.18398 4.21634 4.21732C3.18301 5.25065 2.66634 6.51176 2.66634 8.00065C2.66634 9.48954 3.18301 10.7507 4.21634 11.784C5.24967 12.8173 6.51079 13.334 7.99967 13.334Z"
        fill="#3872FF"
      />
    </SvgIcon>
  )
}

export default InfoIcon
